import React, { useEffect, useState } from 'react';
import GameCard from './GameCard';
import ProgressCircle from './ProgressCircle';
import { fetchImageForGame } from './imageLoader';

const Provider = ({ provider, data, dbData, showOnlyNotLaunchOk, expandedProviders, toggleExpandProvider, selectedPlatform }) => {
    const { total, launchOk, dbGames, games } = data;
    const launchOkPercentage = dbGames > 0 ? (launchOk / dbGames) * 100 : 0;

    const filteredGames = showOnlyNotLaunchOk
        ? games.filter(game => {
            const gameDetails = dbData[game.id] || {};
            const { launchStatus } = gameDetails;
            return launchStatus && launchStatus.real !== 'launchOk';
        })
        : games;

    const isExpanded = expandedProviders.includes(provider);

    const fetchImageUrls = async (gameId) => {
        return await fetchImageForGame(selectedPlatform, gameId);
    };

    const renderGameRow = (game) => {
        const gameDetails = dbData[game.id] || {};

        return (
            <GameCard
                key={game.id}
                game={game}
                gameDetails={gameDetails}
                fetchImageUrls={fetchImageUrls} // Pass fetchImageUrls to GameCard
            />
        );
    };

    return (
        <div className="provider flex-box-c">
            <div className="provider-header">
                <div className="provider-progress flex-box">
                    <div className="flex-box-c">
                        <div className='progress-60'>
                            <ProgressCircle value={launchOkPercentage} text={`${launchOkPercentage.toFixed(2)}%`} />
                        </div>
                    </div>
                </div>
                <div className="provider-name flex-box-col flex-1">
                    <div className='bold-text'>{provider}</div>
                    <div className='info'>
                        <div className={`flex-box ${dbGames < total ? 'highlight' : ''}`}>Testati:{dbGames}/{total}</div>
                    </div>
                </div>
                <button className="expand-button" onClick={() => toggleExpandProvider(provider)}>
                    {isExpanded ? 'Comprimi' : 'Espandi'}
                </button>
            </div>
            {isExpanded && (
                <div className="provider-games">
                    {filteredGames.map(renderGameRow)}
                </div>
            )}
        </div>
    );
};

export default Provider;
