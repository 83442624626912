import React from 'react';

const PlatformButtons = ({ selectedPlatform, handlePlatformChange }) => (
    <div className="platform-buttons">
        <button
            className={`platform-button ${selectedPlatform === 'iPhone' ? 'selected' : ''}`}
            onClick={() => handlePlatformChange('iPhone')}
        >
            iPhone
        </button>
        <button
            className={`platform-button ${selectedPlatform === 'iPad' ? 'selected' : ''}`}
            onClick={() => handlePlatformChange('iPad')}
        >
            iPad
        </button>
        <button
            className={`platform-button ${selectedPlatform === 'Android' ? 'selected' : ''}`}
            onClick={() => handlePlatformChange('Android')}
        >
            Android
        </button>
    </div>
);

export default PlatformButtons;
