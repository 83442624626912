import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProgressCircle = ({ value, text }) => {
    const progressColor = (percentage) => {
        if (percentage >= 80) return '#4CAF50';
        if (percentage >= 50) return '#FFC107';
        if (percentage >= 20) return '#FF9800';
        return '#F44336';
    };

    return (
        <CircularProgressbar
            value={value}
            text={text}
            styles={buildStyles({
                textSize: '22px',
                pathColor: progressColor(value),
                textColor: '#000',
                trailColor: '#d6d6d6'
            })}
        />
    );
};

export default ProgressCircle;
