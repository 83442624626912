// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB5F-veYOq7b3wx4btkIg1a72b4VM8EHDU",
    authDomain: "games-status-2c10d.firebaseapp.com",
    databaseURL: "https://games-status-2c10d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "games-status-2c10d",
    storageBucket: "games-status-2c10d.appspot.com",
    messagingSenderId: "424651635108",
    appId: "1:424651635108:web:27d3a6dafc68061357e931"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const database = getDatabase(app);
const storage = getStorage(app); // Aggiungi questa linea per configurare Firebase Storage

export { auth, provider, database, storage };