// src/imageLoader.js
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";



export const fetchImageForGame = async (platform, gameId) => {
    const storage = getStorage();
    let demoUrl = '';
    let realUrl = '';

    try {
        const demoRef = ref(storage, `${platform}/${gameId}_demo.jpg`); // Assicurati che l'estensione corrisponda
        const realRef = ref(storage, `${platform}/${gameId}_real.jpg`); // Assicurati che l'estensione corrisponda

        demoUrl = await getDownloadURL(demoRef).catch((error) => {
            console.error(`Error fetching demo image for game ${gameId}:`, error);
            return '';
        });

        realUrl = await getDownloadURL(realRef).catch((error) => {
            console.error(`Error fetching real image for game ${gameId}:`, error);
            return '';
        });

    } catch (error) {
        console.error(`Error fetching images for game ${gameId}:`, error);
    }

    return { demoUrl, realUrl };
};

export const fetchImagesForPlatform = async (platform) => {
    const storage = getStorage();
    const demoUrls = {};
    const realUrls = {};

    try {
        const demoListRef = ref(storage, `${platform}`);
        const realListRef = ref(storage, `${platform}`);

        const demoList = await listAll(demoListRef);
        const realList = await listAll(realListRef);

        const demoPromises = demoList.items.map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            const gameId = itemRef.name.split('_')[0];
            demoUrls[gameId] = url;
        });

        const realPromises = realList.items.map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            const gameId = itemRef.name.split('_')[0];
            realUrls[gameId] = url;
        });

        await Promise.all([...demoPromises, ...realPromises]);

    } catch (error) {
        console.error('Error fetching images:', error);
    }

    return { demoUrls, realUrls };
};
