import React from 'react';

const FilterButtons = ({ showOnlyNotLaunchOk, toggleShowOnlyNotLaunchOk }) => (
    <div className="filter-buttons">
        <button className={`show-games-button ${showOnlyNotLaunchOk ? '' : 'red-button'}`}
                onClick={toggleShowOnlyNotLaunchOk}>
            {showOnlyNotLaunchOk ? 'Mostra tutti i giochi' : 'Passa ai giochi KO'}
        </button>
    </div>
);

export default FilterButtons;
