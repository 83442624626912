// src/Legend.js
import React from 'react';
import './Legend.css';
import { statusEmojis } from './GameCard';

const Legend = () => {
    return (
        <div className="legend">
            <h2>Status Info</h2>
            <ul>
                <li>
                    <span className="emoji">{statusEmojis.notLaunched}</span> Not Launched (Game not yet tested).
                </li>
                <li>
                    <span className="emoji">{statusEmojis.launchOk}</span> Launch OK (Game launched successfully).
                </li>
                <li>
                    <span className="emoji">{statusEmojis.launchError}</span> Launch Error (Launch code missing or other launch errors).
                </li>
                <li>
                    <span className="emoji">{statusEmojis.gameError}</span> Game Error (An error occurred during the launch).
                </li>
                <li>
                    <span className="emoji">{statusEmojis.forcedError}</span> Forced Error (Manually tested by user, something is not working).
                </li>
            </ul>
        </div>
    );
};

export default Legend;
