import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, onValue, off } from "firebase/database";
import { auth, database } from './firebase';
import useFetchData from './useFetchData';
import PlatformButtons from './PlatformButtons';
import FilterButtons from './FilterButtons';
import Provider from './Provider';
import ProgressCircle from './ProgressCircle';
import Legend from './Legend';
import './Home.css';

const Home = () => {
    const [apiData, setApiData] = useState(null);
    const [dbData, setDbData] = useState({});
    const [loading, setLoading] = useState(true); // State for loading
    const [error, setError] = useState('');
    const [showOnlyNotLaunchOk, setShowOnlyNotLaunchOk] = useState(false);
    const [expandedProviders, setExpandedProviders] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState('iPhone');
    const [imageUrls, setImageUrls] = useState({ demoUrls: {}, realUrls: {} });
    const navigate = useNavigate();
    const previousDbRef = useRef(null);

    const { fetchData } = useFetchData(selectedPlatform, setApiData, setDbData, setLoading, setError);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchData();
            } else {
                navigate('/login');
            }
        });
    }, [navigate, selectedPlatform]);

    useEffect(() => {
        setImageUrls({ demoUrls: {}, realUrls: {} }); // Reset imageUrls when platform changes
        setLoading(true); // Set loading to true when platform changes
        setDbData({});
    }, [selectedPlatform]);

    useEffect(() => {
        if (showOnlyNotLaunchOk) {
            setExpandedProviders(Object.keys(providerData));
        } else {
            setExpandedProviders([]);
        }
    }, [showOnlyNotLaunchOk]);

    const aggregateGamesByProvider = () => {
        if (!apiData || !apiData.games) return {};

        const providerData = {};
        apiData.games.forEach(game => {
            const gameDetails = dbData[game.id] || {};
            const { launchStatus } = gameDetails;
            const realStatus = launchStatus ? launchStatus.real : '';

            if (!providerData[game.provider]) {
                providerData[game.provider] = {
                    total: 0,
                    launchOk: 0,
                    dbGames: 0,
                    games: []
                };
            }

            providerData[game.provider].total += 1;
            if (gameDetails.hasOwnProperty("gameID")) {
                providerData[game.provider].dbGames += 1;
            }
            if (realStatus === 'launchOk') {
                providerData[game.provider].launchOk += 1;
            }
            providerData[game.provider].games.push(game);
            providerData[game.provider].games.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        });

        return providerData;
    };

    const providerData = aggregateGamesByProvider();

    const toggleShowOnlyNotLaunchOk = () => {
        setShowOnlyNotLaunchOk(prevState => !prevState);
    };

    const toggleExpandProvider = (provider) => {
        setExpandedProviders(prevState =>
            prevState.includes(provider)
                ? prevState.filter(p => p !== provider)
                : [...prevState, provider]
        );
    };

    const handlePlatformChange = (platform) => {
        setSelectedPlatform(platform);
    };

    const dbGames = Object.keys(dbData).filter(gameId => apiData && apiData.games.some(game => game.id === gameId)).length;
    const total = apiData ? apiData.games.length : 0;
    const launchOkGamesCount = Object.keys(dbData).reduce((acc, gameId) => {
        if (dbData[gameId].launchStatus && dbData[gameId].launchStatus.real === 'launchOk' && apiData.games.find(game => game.id === gameId)) {
            acc++;
        }
        return acc;
    }, 0);

    const testedPercentage = apiData && dbData ? (dbGames / total * 100) : 0;
    const testedOkPercentage = apiData && dbData && launchOkGamesCount > 0 ? (launchOkGamesCount / dbGames * 100) : 0;

    // Show loader until all data is loaded
    if (loading) {
        return (
            <div className="loader">
                <div className="spinner"></div>
            </div>
        );
    }


    return (
        <div className="home-container">
            <div className="filter-container flex-box-col">
                <div className="buttons-container">
                    <PlatformButtons selectedPlatform={selectedPlatform} handlePlatformChange={handlePlatformChange} />
                    <FilterButtons showOnlyNotLaunchOk={showOnlyNotLaunchOk} toggleShowOnlyNotLaunchOk={toggleShowOnlyNotLaunchOk} />
                </div>
                <div className="totals flex-box jc-center">
                    <div className="padding-16">
                        <div className={`${dbGames < total ? 'highlight' : ''}`}>
                            <strong>Testati:</strong>
                            <div>{dbGames}/{total}</div>
                        </div>
                        <div className='padding-8 progress-100'>
                            <ProgressCircle value={testedPercentage} text={`${testedPercentage.toFixed(2)}%`} />
                        </div>
                    </div>
                    <div className="padding-16">
                        <strong>Ok:</strong>
                        <div>{launchOkGamesCount}/{dbGames}</div>
                        <div className='padding-8 progress-100'>
                            <ProgressCircle value={testedOkPercentage} text={`${testedOkPercentage.toFixed(2)}%`} />
                        </div>
                    </div>
                </div>
            </div>
            <Legend />
            <div className="provider-data">
                {Object.keys(providerData).sort().map(provider => (
                    <Provider
                        key={provider}
                        provider={provider}
                        data={providerData[provider]}
                        dbData={dbData}
                        showOnlyNotLaunchOk={showOnlyNotLaunchOk}
                        expandedProviders={expandedProviders}
                        toggleExpandProvider={toggleExpandProvider}
                        imageUrls={imageUrls} // Pass imageUrls to Provider
                        setImageUrls={(newUrls) => setImageUrls({ ...imageUrls, ...newUrls })} // Update imageUrls in Home component
                        selectedPlatform={selectedPlatform} // Pass selected platform to Provider
                    />
                ))}
            </div>
        </div>
    );
};

export default Home;
