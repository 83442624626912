// src/api.js
import axios from 'axios';

const fetchDataFromApi = async () => {
    const url = 'https://europe-west1-snai-casino.cloudfunctions.net/game-gameData';
    const data = {
        "data": {
            "model": "postman",
            "version": "1.0",
            "prodID": "SNAI_WEB_CASINO",
            "tf": "1",
            "lan": "it",
            "page": 0,
            "menu": "slotMenu-slot-tutte",
            "gameType": "slot",
            "accessToken": "",
            "sign": "fe449b53b53e26508ce06a0b232b35ea",
            "requestId": "1715344173.8096418102648791"
        }
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data.result;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export default fetchDataFromApi;
