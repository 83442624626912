import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import './GameCard.css';
import './Home.css';

export const statusEmojis = {
    notLaunched: '⚪️',
    launchOk: '🟢',
    launchError: '🟡',
    gameError: '🔴',
    forcedError: '⛔️'
};

const formatDate = (timestamp) => {
    const intervalInMilliseconds = timestamp * 1000;
    const referenceDate = new Date('2001-01-01T00:00:00Z').getTime();
    const finalDateInMilliseconds = referenceDate + intervalInMilliseconds;
    const finalDate = new Date(finalDateInMilliseconds);
    return finalDate.toLocaleString();
};

const GameCard = ({ game, gameDetails, fetchImageUrls }) => {
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [loadingImages, setLoadingImages] = useState(false);
    const [error, setError] = useState(null);
    const [imageUrls, setImageUrls] = useState({ demoUrl: null, realUrl: null });
    const hasFetchedImages = useRef(false);

    const { launchStatus, lastLaunch } = gameDetails || {};
    const demoStatus = launchStatus ? launchStatus.demo : '';
    const demoDate = lastLaunch ? lastLaunch.demo : '';
    const realDate = lastLaunch ? lastLaunch.real : '';
    const realStatus = launchStatus ? launchStatus.real : '';

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '0px 0px 200px 0px',
    });

    useEffect(() => {
        if (inView && !hasFetchedImages.current) {
            setLoadingImages(true);
            fetchImageUrls(game.id)
                .then(urls => {
                    setImageUrls(urls);
                    setLoadingImages(false);
                    hasFetchedImages.current = true;  // Mark images as fetched
                })
                .catch(err => {
                    setError(err);
                    setLoadingImages(false);
                });
        }
    }, [inView, fetchImageUrls, game.id]);

    const openFullscreen = (imageUrl) => {
        setFullscreenImage(imageUrl);
    };

    const closeFullscreen = () => {
        setFullscreenImage(null);
    };

    return (
        <div className="game-card" ref={ref}>
            <div className="bold-text title-text">{game.name}</div>
            <div className="details">
                <p><strong>ID:</strong> {game.id} <span className="platform">({game.platform.id})</span></p>
            </div>
            <div className="flex-box anchored">
                <div className="flex-box-c game-status">
                    <div><strong>Demo</strong></div>
                    <div>{statusEmojis[demoStatus]}</div>
                    <div className="date">{formatDate(demoDate)}</div>
                    {loadingImages ? (
                        <p>Loading image...</p>
                    ) : error ? (
                        <p>No image</p>
                    ) : imageUrls.demoUrl && (
                        <img
                            src={imageUrls.demoUrl}
                            alt={`${game.id} demo`}
                            className="game-image"
                            onClick={() => openFullscreen(imageUrls.demoUrl)}
                            loading="lazy"
                        />
                    )}
                </div>
                <div className="flex-box-c game-status">
                    <div><strong>Real</strong></div>
                    <div>{statusEmojis[realStatus]}</div>
                    <div className="date">{formatDate(realDate)}</div>
                    {loadingImages ? (
                        <p>Loading image...</p>
                    ) : error ? (
                        <p>No image</p>
                    ) : imageUrls.realUrl && (
                        <img
                            src={imageUrls.realUrl}
                            alt={`${game.id} real`}
                            className="game-image"
                            onClick={() => openFullscreen(imageUrls.realUrl)}
                            loading="lazy"
                        />
                    )}
                </div>
            </div>

            {fullscreenImage && (
                <div className="fullscreen-modal" onClick={closeFullscreen}>
                    <img src={fullscreenImage} alt="fullscreen" className="fullscreen-image" />
                </div>
            )}
        </div>
    );
};

export default GameCard;
