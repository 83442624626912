import { useState, useEffect, useRef } from 'react';
import { ref, onValue, off } from 'firebase/database';
import fetchDataFromApi from './api';
import { database } from './firebase';
import { fetchImagesForPlatform } from './imageLoader';

const useFetchData = (selectedPlatform, setApiData, setDbData, setLoading, setError) => {
    const [imageUrls, setImageUrls] = useState({ demoUrls: {}, realUrls: {} });
    const previousDbRef = useRef(null);

    const fetchData = async () => {
        try {
            const apiResponse = await fetchDataFromApi();
            setApiData(apiResponse);
        } catch (error) {
            setError('Failed to fetch data from API');
            setLoading(false);
            return;
        }

        if (previousDbRef.current) {
            off(previousDbRef.current);
        }

        const dbRef = ref(database, selectedPlatform);
        previousDbRef.current = dbRef;

        onValue(dbRef, (snapshot) => {
            const value = snapshot.val();
            setDbData(value || {});
            setLoading(false);
        });

        const images = await fetchImagesForPlatform(selectedPlatform);
        setImageUrls(images);

        return () => {
            off(dbRef);
        };
    };

    useEffect(() => {
        fetchData();
    }, [selectedPlatform]);

    return { imageUrls, fetchData };
};

export default useFetchData;
